import request from "@modules/api";
import dayjs from "dayjs";

let timerIdByDebounce = 0;

export const translateTypeUser = (type) => {
  switch (type) {
    case "user":
      return "Пользователь";
    case "admin":
      return "Администратор";
    case "auditor":
      return "Ревизор";
    case "bookkeeper":
      return "Бухгалтер";
    case "bankingDepartment":
      return "Банкоский отдел";
    default:
      return "";
  }
};

export const asyncDebounce = (url, cb, delay = 800) => {
  if (timerIdByDebounce) {
    clearTimeout(timerIdByDebounce);
  }
  timerIdByDebounce = setTimeout(async () => {
    const result = await request(url);
    cb(result);
  }, delay);
};

export const formatDate = (isoDate) => {
  const date = dayjs(isoDate).format("DD.MM.YYYY HH:mm");
  return date;
};

export const numberFormat = (n) =>
  new Intl.NumberFormat("ru-RU", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(n);

export const parseCurrencyString = (currencyString) => {
  const sanitizedString = currencyString.replace(/[^\d,.-]/g, "").replace(",", ".");
  const parsedNumber = parseFloat(sanitizedString);

  return isNaN(parsedNumber) ? null : parsedNumber;
};
