import React, {useEffect, useState} from "react";
import {Bill} from "@components/Bill/Bill";
import api from "@modules/api";
import {Result} from "antd";
import {Filters} from "@components/BillFilters/Filters";
import {SearchOutlined} from "@ant-design/icons";
import {useSearchParams} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import "./style.css";

export const BillsForPayment = ({toggleLoading}) => {
	const [params, setParams] = useSearchParams();
	const [bills, setBills] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [firms, setFirms] = useState([]);
	const [empty, setEmpty] = useState(false);
	const [filters, setFilters] = useState({
		mode: params.get("mode") || "forPass",
		firm: Number(params.get("firm")) || undefined,
		org: params.get("org")?.split(",") || undefined,
		type: params.get("type")?.split(",") || undefined,
		id: params.get("id") || "",
		priority: params.get("priority") || undefined,
		paid: Boolean(params.get("paid")) || false,
		confirmation: Boolean(params.get("confirmation")) || false,
		canceled: Boolean(params.get("canceled")) || false,
	});

	const updateData = (filters) => {
		const newParams = new URLSearchParams();
		Object.keys(filters).forEach((key) => {
			if (filters[key] && filters[key] !== 0) {
				newParams.set(key, filters[key]);
			}
		});

		setParams(newParams);
		getFiltredBills(newParams);
	};

	const setFilterByTitle = (title, data) => {
		const updatedFilters = {};

		if (Array.isArray(title) && Array.isArray(data)) {
			title.forEach((value, index) => {
				updatedFilters[value] = data[index];
			});
		} else {
			updatedFilters[title] = data;
		}

		const newFilters = {...filters, ...updatedFilters};
		setFilters(newFilters);
	};

	const setOrgs = (ids) => {
		if (!ids.length) {
			setFilters({...filters, org: undefined, type: undefined});
			return;
		}
		const types = [];
		ids.forEach((id) => {
			const org = organizations.find((org) => org.id === id);
			if (org) {
				types.push(org.typeOrganization);
			}
		});
		const newFilters = {...filters, org: ids, type: types};
		setFilters(newFilters);
	};

	const idSearch = () => {
		const newParams = new URLSearchParams(params);
		newParams.set("id", filters.id);
		getFiltredBills(newParams);
	};

	const setMode = (mode) => {
		setFilterByTitle("mode", mode);
		resetFilters("mode", mode);
	};

	const applyFilters = () => {
		updateData(filters);
	};

	const fetchBills = async (params) => {
		const response = await api(`bill/payment?${params}`);
		if (response.status === 200) {
			setBills(response.data);
			if (!response.data.length) {
				setEmpty(true);
			} else {
				setEmpty(false);
			}
		}
	};

	const getFiltredBills = async (params) => {
		toggleLoading();
		await fetchBills(params);
		toggleLoading();
	};

	const fetchFilters = async () => {
		const response = await api("bill/filters");
		if (response) {
			if (response?.status) {
				const [firmsData, sideFirmsData, statesData] = response.data;
				if (firmsData.status) {
					setFirms(firmsData.data);
				}
				const organizationData = [
					...sideFirmsData.data?.map((val) => ({...val, typeOrganization: "organization"})),
					...statesData.data?.map((val) => ({...val, name: val.title, typeOrganization: "state"})),
				].sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});
				setOrganizations(organizationData);
			}
		}
	};

	const getData = async () => {
		const newParams = new URLSearchParams();
		Object.keys(filters).forEach((key) => {
			if (filters[key] && filters[key] !== 0) {
				newParams.set(key, filters[key]);
			}
		});

		setParams(newParams);
		fetchBills(params);
	};

	const fetchInitialData = async () => {
		await Promise.all([fetchFilters(), getData()]);
	};

	useEffect(() => {
		(async () => {
			await fetchInitialData();
			toggleLoading();
		})();
	}, []);

	const removeBill = async () => {
		updateData(filters);
	};

	const resetFilters = (title, data) => {
		const updatedFilters = {};

		if (Array.isArray(title) && Array.isArray(data)) {
			title.forEach((value, index) => {
				updatedFilters[value] = data[index];
			});
		} else {
			updatedFilters[title] = data;
		}
		const newFilters = {
			...filters,
			firm: undefined,
			org: undefined,
			type: undefined,
			id: "",
			priority: undefined,
			paid: false,
			confirmation: false,
			canceled: false,
			...updatedFilters,
		};
		setFilters(newFilters);

		updateData(newFilters);
	};

	return (
		<>
			<div style={{display: "flex", justifyContent: "space-between", gap: "20px", position: "relative"}}>
				<div style={{flex: "1 1 auto"}}>
					<TransitionGroup>
						{bills.map((el) => (
							<CSSTransition
								key={el.id + "_" + el.billConfirmations.findLast((val) => val.status === "new")?.user.id}
								timeout={500}
								classNames="item"
							>
								<Bill bill={el} forPayment={true} showAuthor={true} toggleLoading={toggleLoading}
											removeBill={removeBill}/>
							</CSSTransition>
						))}
					</TransitionGroup>
					{empty &&
						<Result className="no-bills" icon={<SearchOutlined/>} status="info" title="Согласований не найдено"/>}
				</div>
				<div>
					<Filters
						mode={"payment"}
						applyFilters={applyFilters}
						filters={filters}
						firms={firms}
						orgs={organizations}
						setOrgs={setOrgs}
						idSearch={idSearch}
						resetFilters={resetFilters}
						setFilterByTitle={setFilterByTitle}
						setMode={setMode}
					/>
				</div>
			</div>
		</>
	);
};
