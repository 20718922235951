import React, {useEffect, useState} from "react";
import {Modal, Input, Skeleton, message, Typography} from "antd";
import api from "@modules/api";

export const ControlsModal = ({
  id,
  open,
  handleOk,
  handleCancel,
  dataFetch,
  toggleLoading,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [changed, setChanged] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Данные обновлены",
    });
  };

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  useEffect(() => {
    if (open) {
      (async () => {
        setLoading(true);
        const response = await api(`expense/${id}`);
        if (response?.data) {
          if (response?.data.status) {
            setData(response.data);
            setName(response.data?.data?.name);
            setLoading(false);
          } else {
            setLoading(false);
            error("Не удалось загрузить");
          }
        }
      })();
    }
  }, [open]);

  useEffect(() => {
    if (data?.data?.name !== name && name !== "") {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [name]);

  const handleInput = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async () => {
    setConfirming(true);
    const response = await api(`expense/${id}`, {
      method: "PUT",
      body: {name: name},
    });
    setConfirming(false);
    if (response?.data) {
      if (response?.data.status) {
        handleOk();
        toggleLoading();
        dataFetch();
        success();
      } else {
        error("Не удалось сохранить изменения");
      }
    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        title={`Редактирование статьи расходов: ${data?.data?.name}`}
        okText={"Сохранить"}
        confirmLoading={confirming}
        okButtonProps={{disabled: !changed}}
        destroyOnClose
        styles={{body: {height: "200px"}}}
        width={1000}
        open={open}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        {!loading ? (
          <>
            <Typography style={{marginTop: "40px", fontWeight: "500"}}>
              Название
            </Typography>
            <Input title="Название" value={name} onChange={handleInput} />
          </>
        ) : (
          <Skeleton />
        )}
      </Modal>
    </>
  );
};
