import React, {useState, useEffect} from "react";
import {Controls} from "./Controls.jsx";
import api from "@modules/api";
import {asyncDebounce, translateTypeUser} from "@modules/common";
import {Table, message, Drawer, Tabs, List, Select, Button, Typography} from "antd";
import {CreateModal} from "./CreateModal.jsx";
import dayjs from "dayjs";

const {Text} = Typography;

export const Firms = ({toggleLoading}) => {
  const [data, setData] = useState([]);
  const [activeRecord, setActiveRecord] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [expenses, setExpenses] = React.useState([]);
  const [extra, setExtra] = React.useState([]);
  const [bankingDepartment, setBankingDepartment] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);

  const toggleLocalLoading = () => setLoading((prev) => !prev);

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const dataFetch = async () => {
    const response = await api("firms");
    if (response) {
      if (response?.data?.status) {
        setData(response.data.data);
      } else {
        error("Не удалось загрузить данные");
      }
      toggleLoading();
    }
  };

  useEffect(() => {
    (async () => {
      await dataFetch();
    })();
  }, []);

  const updateDataOnDelete = (id, deletedAt) => {
    setData(
      data.map((el) => ({
        ...el,
        ...(el.id === id ? {deletedAt} : null),
      }))
    );
  };

  const getDataByFirm = async (firm) => {
    toggleLocalLoading();
    const response = await Promise.all([
      api(`firms/extra?firmId=${firm.id}&userId=${firm.bankingDepartment}`),
      ...(expenses.length === 0 ? [api("expense")] : []),
    ]);
    setBankingDepartment(response[0].data.bankingDepartment);
    setExtra(response[0].data.extra);
    if (expenses.length === 0) {
      setExpenses(response[1].data.data);
    }
    toggleLocalLoading();
  };

  const eventRow = async (firm) => {
    setActiveRecord(firm);
    setIsOpenDrawer(true);
    await getDataByFirm(firm);
  };

  const closeDraw = () => {
    setIsOpenDrawer(false);
    if (loading) {
      toggleLocalLoading();
    }
  };

  const getValueSelect = (id) => {
    const elem = extra.find((el) => el.expensesId === id);
    return elem ? elem.user : null;
  };

  const setDebounce = (value) => {
    setIsLoadingOptions(false);
    if (value.status === 200) {
      setSelectOptions(
        value.data.map((item) => ({
          label: `${item.f} ${item.i} ${item.o} Почта: ${item.email} Тип: ${translateTypeUser(item.type)}`,
          value: item.id,
        }))
      );
    }
  };

  const searchUsers = (value) => {
    if (value && value.length > 3) {
      setIsLoadingOptions(true);
      asyncDebounce(`user/search/${value}`, setDebounce);
    } else setIsLoadingOptions(false);
  };

  const eventChangeSelectFromBookkeeper = (value, itemId) => {
    const extraExpenses = extra.find((el) => el.expensesId === itemId);
    if (extraExpenses) {
      setExtra(
        extra.map((el) => {
          if (el.expensesId === itemId) {
            return {
              ...el,
              user: value,
            };
          } else return el;
        })
      );
    } else {
      setExtra([
        ...extra,
        {
          expensesId: itemId,
          user: value,
        },
      ]);
    }
  };

  const saveChanges = async () => {
    toggleLocalLoading();
    await api("firms/extra", {
      method: "post",
      body: {
        firmId: activeRecord.id,
        extra,
        bankingDepartment,
      },
    });
    toggleLocalLoading();
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const itemsTabs = [
    {
      key: "1",
      label: "Бухгалтера",
      children: (
        <List
          itemLayout="horizontal"
          dataSource={expenses}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={`${item.number} ${item.name}`}
                description={
                  <Select
                    style={{width: "100%"}}
                    labelInValue
                    filterOption={false}
                    showSearch
                    loading={isLoadingOptions}
                    options={selectOptions}
                    onChange={(value) => eventChangeSelectFromBookkeeper(value, item.id)}
                    onSearch={searchUsers}
                    placeholder="Выбирете пользователя"
                    value={getValueSelect(item.id)}
                  />
                }
              />
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "2",
      label: "Банковский отдел",
      children: (
        <Select
          style={{width: "100%"}}
          labelInValue
          filterOption={false}
          showSearch
          loading={isLoadingOptions}
          options={selectOptions}
          onChange={setBankingDepartment}
          onSearch={searchUsers}
          placeholder="Выбирете пользователя"
          value={bankingDepartment}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Button onClick={() => setOpen(true)} style={{marginBottom: "10px"}} type="primary">
        Добавить
      </Button>
      <CreateModal dataFetch={dataFetch} handleCancel={handleCancel} handleOk={handleOk} open={open} toggleLoading={toggleLoading} />
      <Table id="firms" bordered dataSource={data}>
        <Table.Column dataIndex={"id"} key={"id"} title={"id"} />
        <Table.Column
          dataIndex={"name"}
          key={"name"}
          title={"Название"}
          render={(value, record) => {
            return (
              <Text type="success" className={"cursor_pointer"} onClick={() => eventRow(record)}>
                {value}
              </Text>
            );
          }}
        />
        <Table.Column
          dataIndex={"deletedAt"}
          key={"deletedAt"}
          title={"Дата удаления"}
          render={(value) => (value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "")}
        />
        <Table.Column
          dataIndex={"id"}
          width={50}
          key={"controls"}
          title={""}
          render={(id, record) => {
            return <Controls updateDataOnDelete={updateDataOnDelete} dataFetch={dataFetch} toggleLoading={toggleLoading} record={record} />;
          }}
        />
      </Table>
      <Drawer
        placement="right"
        title={`Настрока фирмы ${activeRecord?.name}`}
        closable={false}
        loading={loading}
        onClose={closeDraw}
        open={isOpenDrawer}
        size={"large"}
      >
        <Tabs
          defaultActiveKey="1"
          items={itemsTabs}
          tabBarExtraContent={
            <Button type={"primary"} onClick={saveChanges}>
              Сохранить
            </Button>
          }
        />
      </Drawer>
    </>
  );
};
