import React, {useState} from "react";
import {Button, Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {ControlsModal} from "./ControlsModal";

export const Controls = ({id, dataFetch, toggleLoading}) => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title={"Редактировать"}>
        <Button onClick={showModal}>
          <EditOutlined />
        </Button>
      </Tooltip>
      <ControlsModal
        dataFetch={dataFetch}
        toggleLoading={toggleLoading}
        handleCancel={handleCancel}
        handleOk={handleOk}
        id={id}
        open={open}
      />
    </>
  );
};
