import React, {useState} from "react";
import {Button, Tooltip, Popconfirm, message} from "antd";
import {EditOutlined, CloseOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {ControlsModal} from "./ControlsModal";
import api from "@modules/api";

export const Controls = ({record, dataFetch, toggleLoading, updateDataOnDelete}) => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const removeFirm = async () => {
    const response = await api(`firms/${record.id}`, {
      method: "delete",
    });
    if (response.status === 200) {
      updateDataOnDelete(record.id, response.data);
    } else {
      message.error("Ошибка удаления фирмы");
    }
  };

  return (
    <>
      {!record.deletedAt && (
        <>
          <Tooltip title={"Редактировать"}>
            <Button onClick={showModal}>
              <EditOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={"Удаленеие"}>
            <Popconfirm
              title="Удаленеие фирмы"
              onConfirm={removeFirm}
              placement="leftTop"
              description={`Вы действительно хотите удалить фирму ${record.name}?`}
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              <Button style={{marginTop: 10}}>
                <CloseOutlined
                  style={{
                    color: "red",
                  }}
                />
              </Button>
            </Popconfirm>
          </Tooltip>
        </>
      )}
      <ControlsModal
        id={record.id}
        record={record}
        dataFetch={dataFetch}
        handleCancel={handleCancel}
        toggleLoading={toggleLoading}
        handleOk={handleOk}
        open={open}
      />
    </>
  );
};
