import React from "react";
import {Typography, Divider, Select} from "antd";

export const Content = ({index, expenses, users, data, setData, onSearch}) => {
  return (
    <>
      {expenses?.data?.map((value, i) => {
        return (
          <div key={i}>
            <Typography>
              {value.number} {value.name}
            </Typography>
            <Select
              style={{width: "100%"}}
              showSearch
              filterOption={false}
              onSearch={onSearch}
              options={users?.map((item) => {
                return {value: item.id, label: `${item.f} ${item.i} ${item.o} Почта: ${item.email}`};
              })}
              value={(() => {
                const item = data.data[index].find((item) => item.expensesId === value.id);
                if (item) {
                  if (item.user) {
                    return {value: item.user.id, label: `${item.user.f} ${item.user.i} ${item.user.o}`};
                  } else {
                    return item.userId;
                  }
                }
              })()}
              onChange={(userId) => {
                const expenseIndex = data.data[index].findIndex((item) => item.expensesId === value.id);
                if (expenseIndex >= 0) {
                  setData({
                    ...data,
                    data: [
                      ...data.data.slice(0, index),
                      [
                        ...data.data[index].slice(0, expenseIndex),
                        {userId: userId, expensesId: value.id, subId: index},
                        ...data.data[index].slice(expenseIndex + 1),
                      ],
                      ...data.data.slice(index + 1),
                    ],
                  });
                } else {
                  setData({
                    ...data,
                    data: [
                      ...data.data.slice(0, index),
                      [...data.data[index], {userId: userId, expensesId: value.id, subId: index}],
                      ...data.data.slice(index + 1),
                    ],
                  });
                }
              }}
            />
            <Divider />
          </div>
        );
      })}
    </>
  );
};
