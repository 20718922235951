import React, {useState} from "react";
import "./style.css";
import {Button, Form, Input, notification} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import api from "@modules/api";

const Login = ({toggleIsLogin}) => {
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [authorizationLoading, setAuthorizationLoading] = useState(false);

	const changeEmail = (e) => {
		setEmail(e.target.value);
	};

	const changePassword = (e) => {
		setPassword(e.target.value);
	};

	const toggleLoading = () => {
		setAuthorizationLoading((prevState) => !prevState);
	};

	const isDisableLoginBtn = () => {
		return !!email && !!password;
	};

	const onLogin = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (isDisableLoginBtn() || authorizationLoading) {
			toggleLoading();
			const response = await api("auth/login", {
				method: "POST",
				body: {
					email,
					password,
				},
			});
			if (response.status === 200) {
				localStorage.setItem("token", response.data);
				toggleIsLogin(true);
			} else {
				notification["error"]({
					message: "Ошибка авторизации, попробуйте снова!",
					placement: "bottomRight",
				});
			}
			toggleLoading();
		}
	};

	return (
		<div>
			<div style={{textAlign: "center"}}>
				<h3>Добро пожаловать в Resanta CRM</h3>
			</div>
			<div className="center">
				<Form className="form-login"
							name="basic"
							initialValues={{
								remember: true,
							}}
				>
					<h3>Авторизация</h3>
					<Form.Item
						className="mb-4"
						name="username"
						rules={[
							{
								required: true,
								message: "Пожалуйста, укажите ваш email!",
							},
						]}
					>
						<Input placeholder="Username" prefix={<UserOutlined/>} value={email} onChange={changeEmail}/>
					</Form.Item>
					<Form.Item
						className="mb-1"
						name="password"
						rules={[
							{
								required: true,
								message: "Пожалуйста, укажите ваш пароль!",
							},
						]}
					>
						<Input.Password placeholder="Password"
														prefix={<LockOutlined/>}
														value={password}
														onChange={changePassword}/>
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							style={{width: "100%"}}
							disabled={authorizationLoading}
							loading={authorizationLoading}
							onClick={onLogin}
						>
							Войти
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default Login;
