import React, {useEffect, useState} from "react";
import {Modal, Input, Typography, message} from "antd";
import api from "@modules/api";

export const CreateModal = ({open, handleOk, handleCancel, dataFetch, toggleLoading}) => {
  const [newData, setNewData] = useState({});
  const [confirming, setConfirming] = useState(false);
  const [valid, setValid] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Данные обновлены",
    });
  };

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  useEffect(() => {
    if (newData?.name?.length) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [newData]);

  const handleInput = (e) => {
    setNewData({...newData, name: e.target.value});
  };

  const handleSubmit = async () => {
    setConfirming(true);
    const response = await api(`firms`, {
      method: "POST",
      body: newData,
    });
    setConfirming(false);
    if (response.data) {
      if (response.data.status) {
        handleOk();
        toggleLoading();
        dataFetch();
        success();
      } else {
        error("Не удалось сохранить изменения");
      }
    }
  };
  return (
    <>
      {contextHolder}
      <Modal
        title={`Создание фирмы ${newData?.name || ""}`}
        okText={"Сохранить"}
        confirmLoading={confirming}
        okButtonProps={{disabled: !valid}}
        destroyOnClose
        styles={{body: {height: "200px"}}}
        width={1000}
        open={open}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <Typography style={{marginTop: "40px", fontWeight: "500"}}>Название</Typography>
        <Input title="Название" value={newData?.name} onChange={handleInput} />
      </Modal>
    </>
  );
};
