import React, {useState, useEffect} from "react";
import api from "@modules/api";
import {Table, Button, App} from "antd";
import {CreateModal} from "./CreateModal";
import {useNavigate} from "react-router-dom";

export const ChaoticChains = ({toggleLoading}) => {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const {message} = App.useApp();
  const [open, setOpen] = useState(false);

  const error = (text) => {
    message.open({
      type: "error",
      content: text,
    });
  };

  const dataFetch = async () => {
    const response = await api("chain-of-approvals");
    if (response?.data) {
      if (response?.data.status) {
        setData(response.data);
      } else {
        error("Не удалось загрузить данные");
      }
    }
    toggleLoading();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    dataFetch();
  }, []);

  const onRow = (record) => ({
    onClick: () => {
      navigate(`/chaotic-chain/${record.id}`);
    },
  });

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Создать
      </Button>
      <Table style={{marginTop: "10px"}} onRow={onRow} bordered dataSource={data?.data}>
        <Table.Column dataIndex={"id"} key={"id"} title={"id"} />
        <Table.Column dataIndex={"title"} key={"title"} title={"Название"} />
      </Table>
      <CreateModal handleCancel={handleCancel} handleOk={handleOk} open={open} />
    </>
  );
};
