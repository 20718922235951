import {useState} from "react";
import {Modal, Input, Typography, message} from "antd";
import api from "@modules/api";

export const ControlsModal = ({
																id,
																open,
																handleOk,
																handleCancel,
																dataFetch,
																toggleLoading,
																record
															}) => {
	const [localName, setLocalName] = useState(record.name);
	const [confirming, setConfirming] = useState(false);

	const [messageApi, contextHolder] = message.useMessage();

	const success = () => {
		messageApi.open({
			type: "success",
			content: "Данные обновлены",
		});
	};

	const error = (text) => {
		messageApi.open({
			type: "error",
			content: text,
		});
	};

	const handleInput = (e) => {
		setLocalName(e.target.value);
	};

	const handleSubmit = async () => {
		setConfirming(true);
		const response = await api(`firms/${id}`, {
			method: "PUT",
			body: {name: localName},
		});
		setConfirming(false);
		if (response.data) {
			if (response.data.status) {
				handleOk();
				toggleLoading();
				dataFetch();
				success();
			} else {
				error("Не удалось сохранить изменения");
			}
		}
	};

	const changed = () => record.name !== localName;

	return (
		<>
			{contextHolder}
			<Modal
				title={`Редактирование фирмы ${record.name}`}
				okText={"Сохранить"}
				confirmLoading={confirming}
				okButtonProps={{disabled: !changed()}}
				destroyOnClose
				styles={{body: {height: "200px"}}}
				width={1000}
				open={open}
				onOk={handleSubmit}
				onCancel={handleCancel}
			>
				<Typography style={{marginTop: "40px", fontWeight: "500"}}>
					Название
				</Typography>
				<Input title="Название" value={localName} onChange={handleInput}/>
			</Modal>
		</>
	);
};
