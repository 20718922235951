import React, {useState, useEffect} from "react";
import {Controls} from "./Controls";
import api from "@modules/api";
import {Table, message} from "antd";

export const Expenses = ({toggleLoading}) => {
  const [data, setData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const dataFetch = async () => {
    const response = await api("expense");
    if (response?.data) {
      if (response?.data.status) {
        setData(response.data);
      } else {
        error("Не удалось загрузить данные");
      }
    }
    toggleLoading();
  };

  useEffect(() => {
    dataFetch();
  }, []);

  return (
    <>
      {contextHolder}
      <Table bordered dataSource={data?.data}>
        <Table.Column dataIndex={"id"} key={"id"} title={"id"} />
        <Table.Column dataIndex={"number"} key={"number"} title={"Номер"} />
        <Table.Column dataIndex={"name"} key={"name"} title={"Название"} />
        <Table.Column
          dataIndex={"id"}
          width={50}
          key={"controls"}
          title={""}
          render={(id) => {
            return <Controls dataFetch={dataFetch} toggleLoading={toggleLoading} id={id} />;
          }}
        />
      </Table>
    </>
  );
};
