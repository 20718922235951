import React from "react";
import {Card, Typography, Space, InputNumber, Button, Select, Checkbox} from "antd";
import {SearchOutlined, PlusOutlined} from "@ant-design/icons";

export const Filters = ({filters, firms, orgs, setFilterByTitle, idSearch, setOrgs, resetFilters, applyFilters, setMode, openModal, mode}) => {
  return (
    <div style={{position: "sticky", top: 0}}>
      {mode === "payment" ? (
        <Card style={{marginBottom: "10px"}} styles={{body: {padding: "12px"}}}>
          <Typography style={{fontSize: "20px", fontWeight: 600, marginBottom: "8px"}}>Подраздел</Typography>
          <Space direction="vertical">
            <Space>
              <Select
                style={{minWidth: "300px"}}
                onChange={(e) => setMode(e)}
                defaultValue={"forPass"}
                placeholder={"Подраздел"}
                options={[
                  {label: "На согласование и оплату", value: "forPass"},
                  {label: "Пройденные", value: "passed"},
                ]}
                value={filters.mode}
              />
            </Space>
          </Space>
        </Card>
      ) : (
        ""
      )}
      {mode === "my" ? (
        <Button type={"primary"} size="large" style={{marginBottom: 5, width: "100%"}} onClick={openModal}>
          <PlusOutlined /> Создать согласование
        </Button>
      ) : (
        ""
      )}
      <Card styles={{body: {padding: "12px"}}}>
        <Typography style={{fontSize: "20px", fontWeight: 600, marginBottom: "8px"}}>Фильтры</Typography>
        <Space direction="vertical">
          <Space.Compact style={{width: 300}}>
            <InputNumber
              controls={false}
              min="1"
              style={{width: "100%"}}
              placeholder="Номер согласования"
              defaultValue={filters.id}
              value={filters.id}
              onChange={(e) => setFilterByTitle("id", e, true)}
            />
            <Button type="primary" onClick={idSearch} icon={<SearchOutlined />} />
          </Space.Compact>
          <Space>
            <Select
              style={{minWidth: "300px"}}
              onChange={(e) => setFilterByTitle("firm", e)}
              allowClear
              placeholder={"Фирма"}
              options={firms.map(({id, name}) => ({value: id, label: name}))}
              value={firms.length && filters.firm}
            />
          </Space>
          <Space>
            <Select
              style={{minWidth: "300px", maxWidth: "300px"}}
              optionFilterProp="label"
              showSearch
              mode="multiple"
              allowClear
              value={filters.org?.length ? Array.from(filters.org, Number) : []}
              placeholder={"Филиал"}
              onChange={(e) => setOrgs(e)}
              options={orgs?.map((org) => ({value: org.id, label: org?.name || org?.title}))}
            />
          </Space>
          {filters.mode !== "forPass" ? (
            <>
              <Typography style={{fontWeight: 600, fontSize: "16px"}}>Статус счёта</Typography>
              <Space>
                <Checkbox checked={filters.confirmation} onChange={(e) => setFilterByTitle("confirmation", e.target.checked)}>
                  На рассмотрении
                </Checkbox>
              </Space>
              <Space>
                <Checkbox checked={filters.paid} onChange={(e) => setFilterByTitle("paid", e.target.checked)}>
                  Оплачен
                </Checkbox>
              </Space>
              <Space>
                <Checkbox checked={filters.canceled} onChange={(e) => setFilterByTitle("canceled", e.target.checked)}>
                  Отклонён
                </Checkbox>
              </Space>
            </>
          ) : (
            ""
          )}
          <div style={{display: "flex", gap: "12px", marginTop: "10px"}}>
            <Button style={{flex: "1 0 auto"}} onClick={resetFilters}>
              Сбросить
            </Button>
            <Button style={{flex: "1 0 auto"}} onClick={applyFilters} type="primary">
              Применить
            </Button>
          </div>
        </Space>
      </Card>
    </div>
  );
};
