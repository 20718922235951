import {useEffect, useRef, useState} from "react";
import api from "@modules/api";
import {Table, Checkbox, Button, Space, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import "./style.css";

const States = ({toggleLoading}) => {
  const [states, setStates] = useState([]);
  const searchInput = useRef();

  useEffect(() => {
    (async () => {
      const response = await api("state/list");
      if (response.status === 200) {
        setStates(response.data);
      }
      toggleLoading();
    })();
  }, []);

  const setActiveState = (status, id) =>
    setStates(
      states.map((el) => ({
        ...el,
        status: el.id === id ? status : el.status,
      }))
    );

  const saveChange = async () => {
    toggleLoading();
    await api("state/edit", {
      method: "put",
      body: states,
    });
    toggleLoading();
  };

  const getColumnSearchProps = (dataIndex, label) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Поиск по ${label}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{width: 90}}>
            Поиск
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              clearFilters && confirm();
            }}
            size="small"
            style={{width: 90}}
          >
            Сбросить
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
            }}
          >
            Фильтровать
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title", "Название"),
    },
    {
      title: "Активен",
      dataIndex: "status",
      width: 10,
      key: "status",
      render: (value, record) => <Checkbox checked={value} onChange={(e) => setActiveState(e.target.checked, record.id)} />,
    },
  ];

  return (
    <>
      <Table
        dataSource={states}
        bordered
        title={() => (
          <Button type={"primary"} onClick={saveChange}>
            Сохранить
          </Button>
        )}
        columns={columns}
      />
    </>
  );
};

export default States;
