import React, {useEffect, useState} from "react";
import {Button, Tabs, message, Typography} from "antd";
import {Content} from "./Content";
import api from "@modules/api";
import {useParams} from "react-router-dom";
import {asyncDebounce} from "@modules/common";

export const ChaoticChain = ({toggleLoading}) => {
	const params = useParams();
	const [messageApi, contextHolder] = message.useMessage();
	const [data, setData] = useState([]);
	const [expenses, setExpenses] = useState([]);
	const [users, setUsers] = useState([]);

	const success = () => {
		messageApi.open({
			type: "success",
			content: "Цепочка сохранена",
		});
	};

	const error = (text) => {
		messageApi.open({
			type: "error",
			content: text,
		});
	};

	const expensesFetch = async () => {
		const response = await api("expense");
		if (response?.data) {
			if (response?.data.status) {
				setExpenses(response.data);
			} else {
				error("Не удалось загрузить статьи расходов");
			}
		}
	};

	const fetchData = async () => {
		const response = await api(`chain-of-approvals-by-user/${params.id}`);
		if (response?.data) {
			if (response?.data.status) {
				setData(response.data);
			} else {
				error("Не удалось загрузить данные");
			}
		}
		toggleLoading();
	};

	useEffect(() => {
		Promise.all([fetchData(), expensesFetch()]);
	}, []);

	const searchUsers = (value) => {
		if (value && value.length > 3) {
			asyncDebounce(`user/search/${value}`, (response) => {
				if (response?.status === 200) {
					setUsers(response?.data);
				} else {
					error("Не удалось загрузить список пользователей");
				}
			});
		}
	};

	const onEdit = (targetKey, action) => {
		if (action === "add") {
			setData({...data, data: [...data.data, []]});
		}
		if (action === "remove") {
			const index = Number(targetKey);
			setData({...data, data: [...data.data.slice(0, index), ...data.data.slice(index + 1)]});
		}
	};

	const handleSubmit = async () => {
		toggleLoading();
		const response = await api(`chain-of-approvals-by-user/${params.id}`, {
			method: "POST",
			body: data.data
				.filter((arr) => {
					if (arr.length) {
						return arr;
					}
				})
				.map((arr, index) => arr.map((value) => ({...value, subId: index}))),
		});
		if (response?.data) {
			if (response?.data.status) {
				success();
			} else {
				error("Не удалось обновить данные");
			}
		}
		toggleLoading();
	};

	return (
		<>
			{contextHolder}
			<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 10px"}}>
				<Typography>{data?.chain?.data.title || ""}</Typography>
				<Button type="primary" onClick={handleSubmit}>
					Сохранить
				</Button>
			</div>
			<Tabs type="editable-card" onEdit={onEdit} size="middle"
						style={{minHeight: "calc(100vh - 104px)", marginTop: "10px"}}>
				{data?.data?.map((value, index) => {
					return (
						<Tabs.TabPane key={index} tab={index + 1}>
							<Content onSearch={searchUsers} index={index} setData={setData} expenses={expenses} users={users}
											 data={data}/>
						</Tabs.TabPane>
					);
				})}
			</Tabs>
		</>
	);
};
