import React, {useState} from "react";
import {Modal, Input, App, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import api from "@modules/api";

export const CreateModal = ({open, handleOk, handleCancel}) => {
  const [title, setTitle] = useState("");
  const [confirming, setConfirming] = useState(false);
  const {message} = App.useApp();
  const navigate = useNavigate();

  const success = () => {
    message.open({
      type: "success",
      content: "Цепочка создана",
    });
  };

  const error = (text) => {
    message.open({
      type: "error",
      content: text,
    });
  };

  const handleInput = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = async () => {
    setConfirming(true);
    const response = await api(`chain-of-approvals`, {
      method: "POST",
      body: {title: title},
    });
    setConfirming(false);
    if (response?.data) {
      if (response?.data.status) {
        success();
        navigate(`/chaotic-chain/${response?.data.data.id}`);
      } else {
        error("Не удалось сохранить изменения");
      }
    }
  };
  return (
    <>
      <Modal
        title={`Создать хаотическую цепочку`}
        okText={"Создать"}
        confirmLoading={confirming}
        okButtonProps={{disabled: !title.length}}
        destroyOnClose
        styles={{body: {height: "200px"}}}
        width={1000}
        open={open}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <>
          <Typography style={{marginTop: "40px", fontWeight: "500"}}>Название</Typography>
          <Input title="Название" value={title} onChange={handleInput} />
        </>
      </Modal>
    </>
  );
};
