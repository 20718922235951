import React, {useState, useEffect} from "react";
import {Controls} from "./Controls.jsx";
import api from "@modules/api";
import {Table, message, Button} from "antd";
import {CreateModal} from "./CreateModal.jsx";

export const SideFirms = ({toggleLoading}) => {
  const [data, setData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);

  const error = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const dataFetch = async () => {
    const response = await api("side-firms");
    if (response) {
      if (response?.data?.status) {
        setData(response.data);
      } else {
        error("Не удалось загрузить данные");
      }
    }
    toggleLoading();
  };

  useEffect(() => {
    dataFetch();
  }, []);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const dataSource = () => {
    return data?.data?.map((value) => {
      return {id: value.id, name: value.name, key: value.id, chaoticChain: value?.chainOfApprovals?.title, deletedAt: value.deletedAt};
    });
  };

  return (
    <>
      {contextHolder}
      <Button onClick={() => setOpen(true)} style={{marginBottom: "10px"}} type="primary">
        Добавить
      </Button>
      <CreateModal dataFetch={dataFetch} open={open} handleCancel={handleCancel} handleOk={handleOk} toggleLoading={toggleLoading} />
      <Table id="firms" bordered dataSource={dataSource()}>
        <Table.Column dataIndex={"id"} key={"id"} title={"id"} />
        <Table.Column dataIndex={"name"} key={"name"} title={"Название"} />
        <Table.Column dataIndex={"chaoticChain"} key={"chaoticChain"} title={"Цепочка согласований"} />
        <Table.Column dataIndex={"deletedAt"} key={"deletedAt"} title={"Дата удаления"} />
        <Table.Column
          dataIndex={"id"}
          width={50}
          key={"controls"}
          title={""}
          render={(id) => {
            return <Controls dataFetch={dataFetch} toggleLoading={toggleLoading} id={id} />;
          }}
        />
      </Table>
    </>
  );
};
